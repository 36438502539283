import Image from "next/image";
import { NavLink } from '../ui';
import styles from '../style/home/WhyClientsChooseUS.module.css';
import CustomSlideArrow from "../shared/customSlideArrow/CustomSlideArrow";
import  { cmsLoader, staticLoader } from "../../lib/helpers/imageLoader";
import { ITestimonial } from "@/interfaces";

type props = {
    testimoninals: ITestimonial[]
}
export function WhyClientsChooseUS({ testimoninals }: props) {
    if (!testimoninals || testimoninals.length == 0) return null;
    return (
        <>
            <section className="section whyUs bgGray">
                <div className="container">
                    <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20"> Real Stories, Bona Fide Impact</h2>
                    <p className="subHeading textCenter mb46 font16 fontSM14">We deliver what we promise. Here’s what our clients have to say…</p>
                    <div className={styles.clientVideo}>
                        <div id="realStories" className={styles.realStories}>
                            {
                                testimoninals && testimoninals.filter((f) => f.video_url != '').map((f, idx) =>
                                    <div key={'div_' + idx} className={`${styles.VideoBox} bgWhite`}>
                                        <NavLink key={idx} href={f.video_url} target="_blank" rel="noreferrer" >
                                            <figure>
                                                <Image
                                                    width={374}
                                                    height={200}
                                                    src={f.post_relative}
                                                    alt={f.clinet_name}
                                                    loader={cmsLoader}
                                                />
                                                <em className={styles.youtubelogo}>
                                                    <Image
                                                        alt={`${f.clinet_name} - Client Feedback on Interior Company`}
                                                        src='/assets/images/youTube.svg'
                                                        loader={staticLoader}
                                                        height={40}
                                                        width={57}
                                                    />
                                                </em>
                                            </figure>
                                            <p className={styles.videoText}>{f.post_content}</p>
                                            <p className={`${styles.videoName} text000`}>{f.clinet_name}<span>{f.inco_city}</span></p>
                                        </NavLink>
                                    </div>
                                )}
                        </div>
                        {testimoninals.length > 3 && <CustomSlideArrow slideId={"realStories"} slideTileWebWidth={385} slideTileMobWidth={345} />}
                    </div>
                </div>
            </section>
        </>
    )
}