import Image from "next/image";
import {useEffect, useState} from 'react'
import { CarouselProvider, Slider, Slide} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import styles from '../style/home/OurBrandPartners.module.css';
import brandPartner from '../../data/brandPartner.json';
import { CountryCode} from '../../lib/constants';
import imageLoader from "../../lib/helpers/imageLoader";
import { useGlobalContext } from "@/lib/context";


export function OurBrandPartners(props:{countryCode:CountryCode}){
    const [visibleSlides, setVisibleSlides] = useState(7);
    const { props: { isDubaiPage } } = useGlobalContext();
    const data=brandPartner && brandPartner.filter(f=>f.countryCode==props.countryCode) || [];
    useEffect(() => window.innerWidth >= 1024 ? setVisibleSlides(visibleSlides  ) : (window.innerWidth >= 768 ? setVisibleSlides(5) : setVisibleSlides(2.5)))

    return (
        <section className="section ourBrand">
            <div className={`${isDubaiPage ? 'containerFull' : 'container'}`}>
                <h2 className="heading textCenter font26 text222 f500 mb12 fontSM20">Our Brand Partners</h2>
                <p className="subHeading textCenter mb46 font16 fontSM14">An elite list of partners who strengthen our brand promise</p>
                <div className={styles.brandList}>
                    <CarouselProvider naturalSlideWidth={100} naturalSlideHeight={25} totalSlides={data.length} visibleSlides = {visibleSlides} isPlaying ={data.length > 7 ? true : false} interval = {2000} infinite = {true}  >
                        <Slider className={data.length > 7 ? "" :"centerCarousel"}> {data.map((d,idx) => <Slide key={idx} index={idx}>
                            <div key={idx} className={styles.sliderImage}>
                                <Image
                                    src={d.relativePath}
                                    width={130}
                                    height={40}
                                    loader={imageLoader}
                                    alt={d.name +" - Interior Company Partner"}
                                    title={d.name}
                                    className="imgFluid"
                                     />
                            </div>
                        </Slide>)
                        }</Slider>
                    </CarouselProvider>
                </div>
            </div>
        </section>
    );
}